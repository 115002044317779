import { Checkbox } from '@material-ui/core';
import styled from 'styled-components';

import { palette } from '../../theme';

export const StyledWrapper = styled.div`
    display: flex;
    min-width: 10rem;
`;

export const StyledCheckbox = styled(Checkbox)`
    display: flex;
    align-items: baseline;
    padding: 0;

    .MuiIconButton-label {
        background-color: ${palette.grey[900]};
        border-radius: 50%;
    }

    &:hover > .MuiIconButton-label {
        background-color: ${palette.grey[900]};
    }

    &.Mui-checked > .MuiIconButton-label,
    &.Mui-checked:hover > .MuiIconButton-label {
        background-color: ${palette.secondary.main};
    }

    &:focus-within > span {
        outline: 0.1rem solid ${palette.primary.main};
    }

    svg {
        padding: 0.2rem;
    }
`;

export const StyledLabel = styled.label`
    display: inline-block;
    padding: 0 1rem;
    font-weight: 500;
    cursor: pointer;
`;

export const StyledErrorMessage = styled.div<{ $error: any }>`
    color: ${({ $error }) => $error.main};
`;
