import styled from 'styled-components';

import { theme, palette } from '../../theme';
import { AlertMessageTypes } from '@tgg/common-types';

interface StyledAlertMessageContainerProperties {
    type: AlertMessageTypes;
}

export const StyledAlertMessageContainer = styled.div<StyledAlertMessageContainerProperties>`
    display: flex;
    padding: 1.5rem;
    font-weight: normal;
    background-color: ${properties =>
        properties.type === 'alert' ? palette.error.dark : palette.grey['100']};

    border-radius: 0.8rem;
    ${theme.breakpoints.up('desktop')} {
        padding: 3rem;
    }
    a {
        color: ${palette.common.blue};
    }
`;

export const StyledContentContainer = styled.div`
    padding-left: 1rem;
`;

export const Styledlink = styled.a`
    color: ${palette.primary.main};
    text-decoration: none;
`;
