import { Validate } from 'react-hook-form';
import { isValidPhoneNumber } from 'react-phone-number-input';

import { isValidEmail } from '../email';

export const nameValidator = (fieldName: string): Validate<string> => {
    return value => {
        if (value.trim().length === 0) {
            return `${fieldName} is required`;
        }

        if (
            !/^[A-Za-z\u0080-\u024F]+[\s',.A-Za-z\u0080-\u024F-]*$/.test(value)
        ) {
            return `${fieldName} must be letters only`;
        }

        if (value.length > 50) {
            return `${fieldName} must be 50 characters or less`;
        }

        return true;
    };
};

export const companyPositionValidator: Validate<string> = value => {
    if (value.trim().length <= 2) {
        return `Company Position is required`;
    }

    if (!/^[\d\s#'(),./A-Za-z\u0080-\u024F-]*$/.test(value)) {
        return 'Company Position must be letters and numbers only';
    }

    if (value.length > 50) {
        return 'Company Position must be 50 characters or less';
    }

    return true;
};

export const companyNameValidator: Validate<string> = value => {
    if (value.trim().length <= 2) {
        return `Company Name is required`;
    }

    if (!/^[\d\s#'(),./A-Za-z\u0080-\u024F-]*$/.test(value)) {
        return 'Company Name must be letters and numbers only';
    }

    if (value.length > 50) {
        return 'Company Name must be 50 characters or less';
    }

    return true;
};

export const numberOfEmployeesValidator = (
    maxNumberOfEmployees: number,
): Validate<number> => {
    return value => {
        if (value < maxNumberOfEmployees) {
            return `A minimum of ${maxNumberOfEmployees} employees is required`;
        }

        return true;
    };
};

export const telephoneValidator: Validate<string> = value => {
    if (value.trim().length === 0) {
        return 'Your phone number is required';
    }

    if (!isValidPhoneNumber(value)) {
        return 'Select country code and enter a valid phone number';
    }

    return true;
};

export const addressLineValidator: Validate<string> = value => {
    if (!/^[\d\s#'(),./A-Za-z\u0080-\u024F-]*$/.test(value)) {
        return 'Address must be letters and numbers only';
    }

    if (value.length > 50) {
        return 'Address must be 50 characters or less';
    }

    return true;
};

export const cityTownValidator: Validate<string> = value => {
    if (value.trim().length === 0) {
        return 'City / Town is required';
    }

    if (
        // old reqex /^[\d#'A-Za-z\u0080-\u024F]*[\d\s!#'.A-Za-z\u0080-\u024F-]*$/
        !/^[\d'A-Za-z\u0080-\u024F]*[\d\s'.A-Za-z\u0080-\u024F-]*$/.test(value)
    ) {
        return 'City / Town must be letters and numbers only';
    }

    if (value.length > 60) {
        return 'City / Town must be 60 characters or less';
    }

    return true;
};

export const postcodeValidator: Validate<string> = value => {
    if (value.trim().length === 0) {
        return 'Postcode is required';
    }

    if (!/^[\d A-Za-z-]*$/.test(value)) {
        return 'Postcode must be letters and numbers only';
    }

    if (value.length > 10) {
        return 'Postcode must be 10 characters or less';
    }

    return true;
};

export const expiryDateValidator: Validate<string> = value => {
    if (value.trim().length === 0) {
        return 'Enter a valid expiry date';
    }

    if (!/^\d{2}\/\d{2}$/.test(value)) {
        return 'Enter an expiry date with month and year';
    }

    const [month, year] = value.split('/').map(segment => Number(segment));

    if (month > 12 || month < 1) {
        return 'Enter a valid month';
    }

    const currentYear = new Date().getFullYear();

    if (year + 2000 < currentYear) {
        return 'Card has expired';
    }

    if (year + 2000 === currentYear) {
        const currentMonth = new Date().getMonth() + 1;

        if (month < currentMonth) {
            return 'Card has expired';
        }
    }

    return true;
};

export const cardNameValidator: Validate<string> = value => {
    const { NODE_ENV } = process.env;

    if (value.trim().length === 0) {
        return 'Enter name on the card';
    }

    if (value.length < 2) {
        return 'Name must be 2 characters or more';
    }

    if (value.length > 26) {
        return 'Name must be 26 characters or less';
    }

    if (
        (NODE_ENV === 'test' || NODE_ENV === 'development') &&
        // eslint-disable-next-line unicorn/better-regex
        !/^[A-Za-z0-9\u0080-\u024F]+[ A-Za-z0-9\u0080-\u024F-]*$/.test(value)
    ) {
        return 'Name must be letters and digits only';
    }

    if (
        !(NODE_ENV === 'test' || NODE_ENV === 'development') &&
        !/^[A-Za-z\u0080-\u024F]+[ A-Za-z\u0080-\u024F-]*$/.test(value)
    ) {
        return 'Name must be letters only';
    }

    return true;
};

export const emailValidator: Validate<string> = value => {
    return isValidEmail(value) || 'Enter a valid email address';
};

export const emailConfirmationValidator = (
    confirmationValue: string,
    emailValue: string,
): boolean | string => {
    return (
        (isValidEmail(confirmationValue) && emailValue === confirmationValue) ||
        'Please type in a valid confirmation email'
    );
};

export const stringLengthValidator = (
    fieldName: string,
    maxStringLength: number,
): Validate<string> => {
    return value => {
        if (value.length > maxStringLength) {
            return `${fieldName} must be ${maxStringLength} characters or less`;
        }

        return true;
    };
};
