import styled from 'styled-components';

import { palette } from '../../../theme';
import { Paragraph } from '../../Paragraph';

export const StyledContainer = styled.div`
    position: relative;
`;

export const StyledTickboxContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: left;
    margin-top: 1.5rem;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        align-items: center;
        margin: 1.5rem auto 0 auto;
    }
`;

export const StyledErrorMessage = styled.div`
    position: absolute;
    width: 100%;
    margin-top: 0.5rem;
    color: ${palette.error.main};
    text-align: left;
    text-transform: lowercase;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        margin-top: 1.5rem;
        text-align: center;
    }
`;

export const StyledParagraph = styled(Paragraph)`
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        text-align: center;
    }
`;
