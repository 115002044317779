/* istanbul ignore file */
/* eslint-disable import/no-extraneous-dependencies */
import PhoneInput from 'react-phone-number-input';
import styled from 'styled-components';

import { palette } from '../../../theme';

export const StyledPhoneInput = styled(PhoneInput)<{
    $hasMarginTop?: boolean;
}>`
    position: relative;
    display: grid;
    grid-template-areas:
        'label'
        'input'
        'error';
    --PhoneInputCountrySelectArrow-color: ${({ theme }) =>
        palette.primary.main};
    margin-top: ${({ $hasMarginTop }) => $hasMarginTop && '2rem'};

    & .PhoneInputCountry {
        position: absolute;
        top: ${({ label }) => (label ? '4.0rem' : '1.3rem')};
        left: 1.2rem;
        grid-area: flag;
        width: 3.4rem;
    }
    input {
        margin-left: 3.5rem;
    }
    label {
        grid-area: label;
    }
    & .PhoneInputCountrySelect {
        scrollbar-color: ${palette.primary.main} transparent;
        scrollbar-width: thin;
        appearance: none;
        &::-webkit-scrollbar {
            width: 0.4rem;
        }
        &::-webkit-scrollbar-track {
            background-color: ${palette.grey[100]};
        }
        &::-webkit-scrollbar-thumb {
            background-color: ${palette.primary.main};
            border-radius: 0.2rem;
        }
        &::after {
            color: ${palette.primary.main};
            content: '▼';
        }
    }
`;
